<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Abastecimiento - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
             <b-row>

  
                      <b-col md="2">
                        <b-form-group label="Codigo:">
                          <b-form-input disabled class="text-center" v-model="catering.code"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Fecha:">
                          <b-form-input disabled class="text-center" type="date" v-model="catering.date"></b-form-input>
                          <small v-if="errors.date" class="text-danger">Seleccione un fecha</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Almacen:">
                          <b-form-select v-model="catering.id_warehouse" :options="warehouses"></b-form-select>
                          <small v-if="errors.id_warehouse" class="text-danger">Seleccione un almacen</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group label="Observación:">
                          <b-form-input rows="1"  v-model="catering.observation" max-rows="3"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label=".">
                          <b-button class="form-control btn" variant="primary" @click="modalProducts" ><i class="fas fa-cart-plus"></i> LA CARTA (F2)</b-button>
                        </b-form-group>
                      </b-col>

                      

                      <cCateringDetail/>
                      <small v-if="errors.catering_detail" class="col-md-12 form-text text-center text-danger">Agregue las carta</small>

                  </b-row>

              <b-row  class="justify-content-md-center mt-3">
                <b-col md="3">
                  <b-button type="submit" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <!-- Modal Clients -->
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="113" @success="modalProducts" />
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
    
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import EventBus from '@/assets/js/EventBus';
import converter from "@/assets/js/NumberToLetters";
import { mapState,mapActions } from "vuex";

// components
import ModalProducts from './components/ModalProduct'
import cCateringDetail from './components/CateringDetail'
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioAdd",
  props: ["id_catering"],
  components:{
      ModalProducts,
      vSelect,
      LoadingComponent,
      cCateringDetail,
      Keypress: () => import('vue-keypress'),
  },
  data() {
    return {
      module: 'Catering',
      role:3,
      isLoading:false,
      catering: {
        id_catering:'',
        id_user:'',
        id_warehouse:'',
        code:'',
        date:moment(new Date()).local().format("YYYY-MM-DD"),
        observation:'',
        state:'1',
      },
      warehouses:[],
      //errors
      errors: {
        id_warehouse: false,
        date:false,
        catering_detail:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.mLoadResetCateringDetail();
    this.ListWarehouses();
    this.ViewCatering();
  },
  methods: {
    ViewCatering,
    ListWarehouses,
    modalProducts,
    EditCatering,
    Validate,
    ...mapActions('Catering',['mLoadResetCateringDetail','mLoadAddCateringDetail']),
  },

  computed: {
    ...mapState(["url_base"]),
    ...mapState('Catering',['catering_detail']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ViewCatering() {
  let id_catering = je.decrypt(this.id_catering);
  let me = this;
  let url = this.url_base+"catering/view/"+id_catering;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.catering.id_catering = response.data.result.catering.id_catering;
        me.catering.id_user = response.data.result.catering.id_user;
        me.catering.id_warehouse = response.data.result.catering.id_warehouse;
        me.catering.code = response.data.result.catering.code;
        me.catering.date = response.data.result.catering.date;
        me.catering.observation = response.data.result.catering.observation;
        me.catering.state = response.data.result.catering.state;
        let catering_detail = response.data.result.catering_detail;
        for (let index = 0; index < catering_detail.length; index++) {
          const element = catering_detail[index];
          let detail = {
            id_letter:element.id_letter,
            code:element.code,
            name:element.name + (element.presentation.length == 0 ? '': ' - '+element.presentation),
            category_name:element.category_name,
            quantity: parseFloat(element.quantity).toFixed(2),
          }
          me.mLoadAddCateringDetail(detail);
          
        }
    } 
  })
}

function modalProducts() {
  EventBus.$emit('ModalLetterShow',2);
}

function ListWarehouses() {

  let me = this;
  let url = this.url_base + "warehouse/list-active";

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      me.warehouses.push({value : '', text: '-- Seleccione un almacen --'});
      if (response.data.status == 200) {
        let data = response.data.result;
        for (let index = 0; index < data.length; index++) {
          me.warehouses.push( { value : data[index].id_warehouse , text: data[index].name } );
        }
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function EditCatering() {
  let me = this;
  let url = me.url_base + "catering/edit";
  me.isLoading = true;
  me.catering.catering_detail = me.catering_detail;
  let data = me.catering;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token,module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message,timer: 2000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error',timer: 2000,})
        me.isLoading = false;
    });
}

function Validate() {

  this.validate = false;

  this.errors.id_warehouse = this.catering.id_warehouse.length == 0 ? true : false;
  this.errors.date = this.catering.date.length == 0 ? true : false;
  this.errors.catering_detail = this.catering_detail.length == 0 ? true : false;

  if (this.errors.id_warehouse == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.catering_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  if (this.validate) {
    return false;
  }

  let me = this;

  Swal.fire({
    title: 'Esta seguro de modificar el abastecimiento?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.EditCatering();
    }
  })


}



</script>
